import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/SEO';
import { PlaceHero } from 'components/PlaceHero';
import {
  PHAN_TRUCK_NOODLE,
  PHAN_TRUCK_BAO,
  PHAN_TRUCK_SOUP,
  PHAN_TRUCK_SIDES,
  PHAN_TRUCK_DIPS,
} from '../data/food';
import { FoodList } from 'components/FoodList';
import { Food } from 'components/Food';
import { InfoBox } from 'components/InfoBox';

const MrPhanPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "venue-05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "venue-05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      food: allFile(filter: { name: { regex: "/(truck-)/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout>
      <SEO title="Mr. Phan House Truck" />
      <div className="max-w-screen-xl px-6 sm:mx-auto py-4 md:py-8">
        <PlaceHero
          title="Mr. Phan House Truck"
          address={[
            {
              street: '',
              city: ' ',
              map: '',
            },
          ]}
          social={{
            facebook:
              'https://www.facebook.com/Mr-Phan-House-Truck-101509748245152',
            instagram: 'https://www.instagram.com/mrphanhouse/',
          }}
          sources={sources}
          openingHours={[
            {
              title: 'Fredag - Søndag',
              time: '16:00-21:00',
            },
          ]}
          noTitle={true}
        />

        {/* <InfoBox /> */}

        <div className="md:flex mb-12">
          <div className="mb-16 md:mb-0 md:pr-8 md:w-6/12">
            <h3 className="font-bold text-2xl leading-none mb-2">
              Mobil Food Truck
            </h3>
            <p className="leading-relaxed">
              Vi er en mobil food truck, følg os på facebook for at se, hvor vi
              kører hen. Vores retter vil løbende blive skiftet.
            </p>
          </div>

          <div className="md:w-6/12">
            <h3 className="font-bold text-2xl leading-none mb-2">
              Booking til event
            </h3>
            <p className="leading-relaxed">
              Er du på udkig efter noget nyt og spændende mad fra Asien, eller
              vi sammen kunne lave en menu til dit kommende arrangement? Vi
              bookes fra private barnedåb til bryllupper 😊 kontakt os for
              nærmere info på{' '}
              <a className="text-phan" href="tel:26563581">
                26563581
              </a>{' '}
              eller{' '}
              <a className="text-phan" href="mail:mrphanhouse@gmail.com">
                mrphanhouse@gmail.com
              </a>
            </p>
          </div>
        </div>

        {/* <div className="px-4 md:flex items-center justify-center mb-8 py-4 font-semibold text-center border-2 border-gray-800">
          Det anbefales at ringe og bestille på forhånd, for at undgå kø -
          <a href="tel:26563581">26 56 35 81</a>
        </div> */}

        {/* <FoodList title="Baos">
          {PHAN_TRUCK_BAO.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Supper">
          {PHAN_TRUCK_SOUP.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Nudler">
          {PHAN_TRUCK_NOODLE.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Sides">
          {PHAN_TRUCK_SIDES.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Dips">
          {PHAN_TRUCK_DIPS.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList> */}
      </div>
    </Layout>
  );
};

export default MrPhanPage;
